import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../styles/banner.css"
import "../styles/contact-btn.css"
import "../styles/section.css"
import "../styles/servicios.css"

import flecha from "../images/arrow-down.svg"
import bgBanner from "../images/Servicios/bgImage.jpg"

import briefcase from "../images/Servicios/briefcase.svg"
import openbook from "../images/Servicios/openbook.svg"

import libreta from "../images/Servicios/libreta.svg"
import senal from "../images/Servicios/senal.svg"

import book from "../images/Servicios/book.svg"
import database from "../images/Servicios/database.svg"
import home from "../images/Servicios/home.svg"

import users from "../images/Servicios/users.svg"
import file_text from "../images/Servicios/file_text.svg"

import servicios_1 from "../images/Servicios/servicios_1.jpg"
import servicios_2 from "../images/Servicios/servicios_2.jpg"
import servicios_3 from "../images/Servicios/servicios_3.jpg"

const ServiciosPage = () => (
  <Layout whiteFooter={true}>
    <SEO title="Servicios"
    url = "https://www.veleztax.com/servicios"
    image = {bgBanner}
    description = "Analizamos, asesoramos e implementamos."
     />
    <section className="banner nosotros_banner">
      <h1>Nuestros Servicios</h1>
      <p>Analizamos, asesoramos e implementamos.</p>

      <div
        className="banner__picture"
        style={{ backgroundImage: "url(" + bgBanner + ")" }}
      />
    </section>
    <section className="section">
      <div className="section_2">
        <div className="servicio__item servicio__item__first">
          <div className="section_title">
            <img className="section_title__img" src={briefcase} alt="maletín" />
            <h2>Proyecto M&A</h2>
          </div>
          <p style={{ maxWidth: "100%" }}>
            Diseño de estructuras eficientes desde un punto de vista fiscal,
            legal y de negocios para la implementación de proyectos de M&A.
          </p>
          <Link className="contact-btn" to="/contacto">
            Contáctanos
            <img src={flecha} alt="flecha" />
          </Link>
        </div>
      </div>
    </section>
    <section
      style={{ backgroundColor: "#f8f8f9" }}
      className="servicios__container servicios__container__first"
    >
      <div className="col small-col desktop">
        <div
          className="servicios__sideImage"
          style={{ backgroundImage: "url(" + servicios_1 + ")" }}
        />
      </div>
      <div className="col small-col">
        <div className="servicio__item">
          <div className="section_title">
            <img
              className="section_title__img"
              src={openbook}
              alt="libro abierto"
            />
            <h2>Due Dilligence</h2>
          </div>
          <p>
            Due diligence fiscal solicitados por nuestros clientes para efectuar
            la adquisición o enajenación de sociedades.
          </p>
          <Link className="contact-btn" to="/contacto">
            Contáctanos
            <img src={flecha} alt="flecha" />
          </Link>
        </div>
        <div className="servicio__item">
          <div className="section_title">
            <img
              className="section_title__img"
              src={file_text}
              alt="documento"
            />
            <h2>Memorandums</h2>
          </div>
          <p>
            Análisis y preparación de memorándums sobre transacciones
            particulares o temas de compleja interpretación, con base en las
            disposiciones fiscales locales, o en su caso, de los tratados para
            evitar la doble imposición fiscal celebrados por México.
          </p>
          <Link className="contact-btn" to="/contacto">
            Contáctanos
            <img src={flecha} alt="flecha" />
          </Link>
        </div>
        <div className="servicio__item">
          <div className="section_title">
            <img className="section_title__img" src={book} alt="libro" />
            <h2>Modelos Fiscales</h2>
          </div>
          <p>
            Desarrollo de modelos fiscales que fueran congruentes con los
            modelos financieros que soportan los proyectos de nuestros clientes.{" "}
          </p>
          <Link className="contact-btn" to="/contacto">
            Contáctanos
            <img src={flecha} alt="flecha" />
          </Link>
        </div>
      </div>
      <div className="col small-col mobile">
        <div
          className="servicios__sideImage"
          style={{ backgroundImage: "url(" + servicios_1 + ")" }}
        />
      </div>
    </section>
    <section className="servicios__container servicios__container__right">
      <div className="col small-col">
        <div className="servicio__item">
          <div className="section_title">
            <img className="section_title__img" src={libreta} alt="libreta" />
            <h2>Revisión de Documentos Legales</h2>
          </div>
          <p>
            Analizamos la documentación legal requerida para la implementación
            de algún proyecto, a efectos de identificar riesgos y áreas de
            oportunidad para el diseño de estructuras fiscales que soporten
            dicho proyecto.
          </p>
          <Link className="contact-btn" to="/contacto">
            Contáctanos
            <img src={flecha} alt="flecha" />
          </Link>
        </div>
        <div className="servicio__item">
          <div className="section_title">
            <img className="section_title__img" src={home} alt="casa" />
            <h2>Contratos de Compraventa</h2>
          </div>
          <p>
            Revisión de contratos de compraventa y participación en la
            negociación del precio final de la transacción o incluir en dichos
            contratos, los cláusulas que resulten necesarias.
          </p>
          <Link className="contact-btn" to="/contacto">
            Contáctanos
            <img src={flecha} alt="flecha" />
          </Link>
        </div>
        <div className="servicio__item">
          <div className="section_title">
            <img
              className="section_title__img"
              src={database}
              alt="base de datos"
            />
            <h2>Estructuras Financiamiento</h2>
          </div>
          <p>
            Análisis de las características particulares de una sociedad o
            proyecto para definir la estructura de financiamiento
            (deuda/capital) óptima, considerando todas las reglas de
            deducibilidad de los intereses.
          </p>
          <Link className="contact-btn" to="/contacto">
            Contáctanos
            <img src={flecha} alt="flecha" />
          </Link>
        </div>
      </div>
      <div className="col small-col desktop">
        <div
          className="servicios__sideImage servicios__sideImage__right"
          style={{ backgroundImage: "url(" + servicios_2 + ")" }}
        />
      </div>
      <div className="col small-col mobile">
        <div
          className="servicios__sideImage"
          style={{ backgroundImage: "url(" + servicios_2 + ")" }}
        />
      </div>
    </section>
    <section
      style={{ backgroundColor: "#f8f8f9" }}
      className="servicios__container"
    >
      <div className="col small-col desktop">
        <div
          className="servicios__sideImage"
          style={{ backgroundImage: "url(" + servicios_3 + ")" }}
        />
      </div>
      <div className="col small-col">
        <div className="servicio__item">
          <div className="section_title">
            <img className="section_title__img" src={senal} alt="señal" />
            <h2>Reporte Inversiones Patrimoniales</h2>
          </div>
          <p>
            Cálculo de los efectos fiscales mexicanos derivados de las
            inversiones en el extranjero de personas físicas residentes en
            México
          </p>
          <Link className="contact-btn" to="/contacto">
            Contáctanos
            <img src={flecha} alt="flecha" />
          </Link>
        </div>
        <div className="servicio__item">
          <div className="section_title">
            <img
              className="section_title__img"
              src={file_text}
              alt="documento"
            />
            <h2>Declaraciones Informativas REFIPREs</h2>
          </div>
          <p>
            Asesoramos la presentación de declaraciones informativas por
            inversiones en vehículos transparentes
          </p>
          <Link className="contact-btn" to="/contacto">
            Contáctanos
            <img src={flecha} alt="flecha" />
          </Link>
        </div>
        <div className="servicio__item">
          <div className="section_title">
            <img className="section_title__img" src={users} alt="usuarios" />
            <h2>Revisión Nóminas</h2>
          </div>
          <p>
            Revisamos las nóminas de nuestros clientes para determinar que todos
            los conceptos que perciben sus empleados son deducibles
          </p>
          <Link className="contact-btn" to="/contacto">
            Contáctanos
            <img src={flecha} alt="flecha" />
          </Link>
        </div>
      </div>
      <div className="col small-col mobile">
        <div
          className="servicios__sideImage"
          style={{ backgroundImage: "url(" + servicios_3 + ")" }}
        />
      </div>
    </section>
  </Layout>
)

export default ServiciosPage
